import React from 'react';
import { Blank } from 'grommet-icons';

const IconPaintRoller = (props) => (
  <Blank viewBox="0 0 40 40" {...props}>
    <path d="M32.8823,12.9957V5.564H29.0836V4.7465a2.1023,2.1023,0,0,0-2.1-2.1H9.2177a2.1023,2.1023,0,0,0-2.1,2.1v3.488a2.1023,2.1023,0,0,0,2.1,2.1H26.9836a2.1023,2.1023,0,0,0,2.1-2.1V6.964h2.3987v5.1991l-12.63,6.864v5.1468H16.8842v13.18h4.9424v-13.18H20.2519V19.86ZM27.6836,8.2345a.7007.7007,0,0,1-.7.7H9.2177a.7009.7009,0,0,1-.7-.7V4.7465a.7009.7009,0,0,1,.7-.7H26.9836a.7007.7007,0,0,1,.7.7Zm-7.257,27.719H18.2842v-10.38h2.1424Z" />
  </Blank>
);

export default IconPaintRoller;
